import * as React from "react"

function SvgBurger(props) {
  return (
    <svg width={21.386} height={19.377} viewBox="0 0 21.386 19.377" {...props}>
      <g data-name="Groupe 7296">
        <g data-name="Groupe 3940">
          <g data-name="Groupe 7283">
            <path
              d="M0 .5h21.386"
              fill="none"
            />
          </g>
          <g data-name="Groupe 7284">
            <path
              d="M0 9.688h21.386"
              fill="none"
            />
          </g>
          <g data-name="Groupe 7285">
            <path
              d="M0 18.877h21.386"
              fill="none"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgBurger