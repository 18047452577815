import React, { useEffect, useState } from "react"
import SEO from "../components/seo"
import BackgroundImage from 'gatsby-background-image'

import LogoOO from "../components/svg/logo_OO";
import { IntersectionObserver } from "../components/intersection/intersection";
import { AppearAnimText } from "../components/animations/text_fade";
import { Link, graphql } from "gatsby"
import { connect } from 'react-redux';
import * as animationLogoIntro from "../components/animations/lottie/coignet_anim_logo.json"
import * as animationLogoOO1 from "../components/animations/lottie/OO_anim.json"

import Lottie from 'react-lottie';
import { motion, AnimateSharedLayout, AnimatePresence, useSpring, useTransform, useViewportScroll } from 'framer-motion';
import Header from "../components/header/header"
import { Footer } from "../components/footer/footer";
import { useDispatch } from 'react-redux';

import {
  isMobile,
  isBrowser,
  isMobileOnly
} from "react-device-detect";
import { LottieWrapper } from "../components/animations/lottie";
import { IntersectionObserverSticky } from "../components/intersection/intersectionSticky";

const titleanimation = {
  initial: {
    opacity: 0,
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 2.5,
    }
  },
  show: {
    opacity: 1,
    transition: {
      type: 'spring',
      damping: 10,
      stiffness: 20,
      mass: 0.5,
      duration: 2.5,
      staggerChildren: 0.35
    }
  },
}

const textAppearFromLeft = {
  initial: {
    opacity: 0,
  },
  hidden: {
    opacity: 0,
    x: -50,
  },
  show: {
    opacity: 1, x: 0,
    transition: {
      type: 'spring',
      damping: 10,
      stiffness: 20,
      mass: 0.5,
    }
  },
}

const textAppearFromRight = {
  initial: {
    opacity: 0,
  },
  hidden: {
    opacity: 0,
    x: 50,
  },
  show: {
    opacity: 1, x: 0,
    transition: {
      type: 'spring',
      damping: 10,
      stiffness: 20,
      mass: 0.5,
    }
  },
}

const paragraphAppear = {
  initial: {
    opacity: 0,
    y: 70
  },
  hidden: {
    opacity: 0,
    y: 70,
    transition: {
      duration: 2.5,
    }
  },
  show: {
    opacity: 1, y: 0,
    transition: {
      type: 'spring',
      damping: 10,
      stiffness: 20,
      mass: 0.5,
    }
  },
}

const IndexPage = ({ data, isIntro, location, projectShow, project, filterTags, filterProjects }) => {

  const { scrollYProgress } = useViewportScroll();

  return (
    <>
      <SEO title="société d’investissement indépendante spécialisée dans le secteur immobilier" className=" max-w-lg mx-auto" />
      <div id="mainIndex" className="relative">
        <Header location={location} />
        <Confiance data={data} isIntro={isIntro} scrollYProgress={scrollYProgress} />
        <Révéler data={data} scrollYProgress={scrollYProgress} />
        <Expertise data={data} scrollYProgress={scrollYProgress} />
        <Valeurs data={data} scrollYProgress={scrollYProgress} />
        <Realisations data={data} filterProjects={filterProjects} filterTags={filterTags} projectShow={projectShow} project={project} />
      </div>
    </>
  )
}

const Confiance = ({ data, isIntro, scrollYProgress }) => {

  const [isStopped, setIsStopped] = useState(false);
  const [WindowScope, setWindowScope] = useState();
  const [windowHeightState, setWindowHeightState] = useState();
  const [windowWidthState, setWindowWidthState] = useState();

  const AnimLogoIntro = {
    loop: false,
    autoplay: true,
    animationData: animationLogoIntro.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const AnimLogoIntroStatic = {
    loop: false,
    autoplay: true,
    animationData: animationLogoIntro.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const AnimLogoOO = {
    loop: false,
    autoplay: true,
    animationData: animationLogoOO1.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    const windowGlobal = typeof window !== 'undefined' && window;
    setWindowScope(windowGlobal);

    function setValues() {
      setWindowWidthState(windowGlobal.innerWidth);
      setWindowHeightState(windowGlobal.innerHeight);
    };
    setValues();
    windowGlobal.addEventListener('resize', setValues);
    return () => windowGlobal.removeEventListener('resize', setValues);
  }, [WindowScope, windowWidthState, windowHeightState]);

  let WindowScopeValue = WindowScope;

  function gotoTop() {
    WindowScopeValue.scrollTo({ top: 0, behavior: 'smooth' });
  }

  // const scaleLogoTransition = useTransform(scrollY, [0, 0], [1, 0.22]);
  // const scaleLogoTransitionSpring = useSpring(scaleLogoTransition, { damping: 100, stiffness: 100, mass: 1 })

  // const yLogoTransition = useTransform(scrollY, [0, 0], ["0%", "-130%"]);
  // const yLogoTransitionSpring = useSpring(yLogoTransition, { damping: 100, stiffness: 100, mass: 1 })

  const logoScale = {
    initial: {
      scale: 1,
      originX: 0,
      originY: 0,
    },
    enter: {
      originX: 0,
      originY: 0,
      opacity: 0,
      transition: {
        type: 'spring',
        damping: 100,
        stiffness: 100,
        mass: 1,
        delay: 0.5,
        duration: 0.8,
      },
    },
    exit: {
    },
  };

  const logoYAnim = {
    initial: {
      y: "0%"
    },
    enter: {
      transition: {
        type: 'spring',
        damping: 100,
        stiffness: 100,
        mass: 1,
        delay: 0.5,
        duration: 0.8,
      },
    },
    exit: {
    },
  };

  const scrollY = scrollYProgress;
  const scaleImg1 = useTransform(scrollY, [0, 0.1], [1, 1.2]);
  const scaleImg1Spring = useSpring(scaleImg1, { damping: 100, stiffness: 100, mass: 1 })

  return (
    <>
      <section id="section-presentation" className="w-screen h-screen relative flex items-center justify-center">
        {/* <AnimatePresence>
          {(isIntro !== true && !isMobileOnly) &&
            <motion.div
              initial="initial"
              animate="enter"
              exit="exit"
              variants={logoScale}
              className="pointer-events-none w-screen h-screen fixed top-0 left-0 flex flex-col items-center justify-center z-100">
              <motion.div
                onClick={() => gotoTop()}
                variants={logoYAnim}
                className="pointer-events-auto min-height-logo cursor-pointer relative w-1/2 z-100"
              >
                <Lottie options={AnimLogoIntroStatic}
                  height="auto"
                  href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
                  width="100%"
                  speed={0.8}
                  isStopped={isStopped}
                  isPaused={isStopped}
                />
              </motion.div>
              <div className="w-full text-center md:text-2xl xxl:text-3xl shadow-white z-10 overflow-hidden sofia-bold uppercase text-grey-edmond tracking-widest2 opacity-0 whitespace-no-wrap">la confiance, notre plus bel actif</div>
            </motion.div>
          }
        </AnimatePresence> */}
        <div className="w-screen h-screen absolute top-0 left-0 flex flex-col items-center justify-center z-50">
          <div className="relative w-full md:w-1/2 pointer-events-none">
            <div className={!isMobileOnly ? "opacity-0" : ""}>
              <Lottie options={AnimLogoIntro}
                height="auto"
                href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
                width="100%"
                speed={0.8}
                isStopped={isStopped}
                isPaused={isStopped}
              />
            </div>
          </div>
          {(isIntro !== true && !isMobileOnly) && <div className="w-full md:text-2xl xxl:text-3xl text-center shadow-white z-10 overflow-hidden sofia-bold uppercase text-grey-edmond tracking-widest2 whitespace-no-wrap">la confiance, notre plus bel actif</div>}
          {(isIntro !== true && isMobileOnly) &&
            <div className="w-full md:text-2xl xxl:text-3xl text-center shadow-white z-10 overflow-hidden sofia-bold uppercase text-grey-edmond tracking-widest2 whitespace-no-wrap">
              la confiance,<br></br> notre plus bel actif
            </div>
          }
        </div>
        {isIntro !== true &&
          <div className="w-10/12 absolute z-10 left-0 bottom-0">
            <Lottie options={AnimLogoOO}
              height="auto"
              href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
              width="100%"
              speed={0.8}
              isStopped={isStopped}
              isPaused={isStopped}
            />
          </div>
        }
        <div className="w-full h-full relative overflow-hidden">
          <motion.div style={{ scale: scaleImg1Spring, transformOrigin: "0.5 0.5" }} className="w-full h-full absolute top-0 left-0">
            <BackgroundImage
              Tag="section"
              id="imageConfiance"
              className={`w-full ${isMobileOnly && 'opacity-50'} h-screen`}
              fluid={data.confianceImage.childImageSharp.fluid}
              backgroundColor={`#CFDEE1`}
            >
            </BackgroundImage>
          </motion.div>
        </div>
      </section>
    </>
  )
}

const Révéler = ({ data, scrollYProgress }) => {

  const scrollY = scrollYProgress;

  const Parralaxvalue = useTransform(scrollY, [0.1, 0.2], [100, 0]);
  const ParralaxvalueSpring = useSpring(Parralaxvalue, { damping: 100, stiffness: 100, mass: 1 })

  const ParralaxvalueMobile = useTransform(scrollY, [0.1, 0.2], [100, 0]);
  const ParralaxvalueSpringMobile = useSpring(ParralaxvalueMobile, { damping: 100, stiffness: 100, mass: 1 })

  return (
    <>
      <section id="section-groupe" className={`${isMobileOnly ? "overflow-hidden" : "overflow-visible"} relative pb-0 desktop:pb-40 background-blue-edmond`}>
        <section className="overflow-visible background-blue-edmond w-full min-h-screen relative flex items-center justify-center desktop:justify-start desktop:items-start xxl:justify-center xxl:items-center">
          <div className={`relative mt-10 md:mt-20 ${isBrowser ? "desktop:mt-0" : ""} flex flex-col w-full justify-center items-center`}>
            <div className="z-30 flex flex-col w-10/12">
              <IntersectionObserver isReset={true} >
                <AppearAnimText>
                  <motion.h1
                    initial={{ opacity: 0 }}
                    variants={titleanimation}
                    className="w-full uppercase sofia-semibold text-4xl xs:text-5xl md:text-6xl xxl:text-7xl leading-tight stroke-blue-thin">
                    <motion.div variants={textAppearFromLeft}>Révéler le meilleur<br></br></motion.div>
                    <motion.div variants={textAppearFromLeft}>des actifs<br></br></motion.div>
                    <motion.div variants={textAppearFromLeft}>immobiliers</motion.div>
                  </motion.h1>
                </AppearAnimText>
              </IntersectionObserver>
              <IntersectionObserver isReset={true} >
                <AppearAnimText>
                  <motion.div variants={paragraphAppear}>
                    <p className="pt-10 w-full md:w-1/3 text-dark-edmond sofia-light text-base xs:text-lg md:text-sm xxl:text-sm leading-7  md:leading-8">
                      <span className="text-darkblue-edmond sofia-bold">Coignet IM est une société d’investissement indépendante
                      spécialisée dans le secteur immobilier. </span>
                      Depuis plus de 20 ans, la société investit et opère activement sur toutes classes d’actifs
                      – résidentiel, bureaux, commerces, hôtellerie et activité –, principalement à Paris et région parisienne.
                      </p>
                    <p className="pt-10 w-full md:w-1/3 text-dark-edmond sofia-light text-base xs:text-lg md:text-sm xxl:text-sm leading-7  md:leading-8">
                      <span className="text-darkblue-edmond sofia-bold">L’expertise, l’agilité et la fiabilité de la société en ont fait une plateforme de confiance</span> pour ses co-investisseurs et partenaires,
                      au service d’une double stratégie d’investissement :
                      une stratégie d’investissements locatifs via une foncière (core+), et une stratégie de valorisation sur des cycles plus courts (value-add).
        </p>
                  </motion.div>
                </AppearAnimText>
              </IntersectionObserver>
            </div>
            <motion.div style={{ y: !isMobileOnly ? ParralaxvalueSpring : ParralaxvalueSpringMobile }} className="relative md:absolute z-20 right-0 mt-20 md:mt-40 top-0 w-full md:w-1/2 h-screen-1/2 md:h-screen-3/4">
              <BackgroundImage
                Tag="section"
                className="w-full h-full"
                fluid={data.RevelerImage2.childImageSharp.fluid}
                backgroundColor={`#CFDEE1`}
              >
              </BackgroundImage>
            </motion.div>
          </div>
          {!isMobile &&
            <div className="absolute z-10 left-0 top-0 -mt-20 w-2/3 h-screen-3/4">
              <BackgroundImage
                Tag="section"
                className="w-full h-screen"
                fluid={data.RevelerImage1.childImageSharp.fluid}
                backgroundColor={`#CFDEE1`}
              >
              </BackgroundImage>
            </div>
          }
        </section>
      </section>
    </>
  )
}

const Expertise = ({ data, scrollYProgress }) => {

  const scrollY = scrollYProgress;

  const Parralaxvalue = useTransform(scrollY, [0.2, 0.3], [100, 0]);
  const ParralaxvalueSpring = useSpring(Parralaxvalue, { damping: 100, stiffness: 100, mass: 1 })

  const Parralaxvalue2 = useTransform(scrollY, [0.3, 0.4], [200, 0]);
  const ParralaxvalueSpring2 = useSpring(Parralaxvalue2, { damping: 100, stiffness: 100, mass: 1 })

  const Parralaxvalue2Mobile = useTransform(scrollY, [0.2, 0.3], [0, -50]);
  const ParralaxvalueSpring2Mobile = useSpring(Parralaxvalue2Mobile, { damping: 100, stiffness: 100, mass: 1 })

  const Parralaxvalue3 = useTransform(scrollY, [0.3, 0.4], [0, -50]);
  const ParralaxvalueSpring3 = useSpring(Parralaxvalue3, { damping: 100, stiffness: 100, mass: 1 })

  //1

  const ParralaxvalueNbr1 = useTransform(scrollY, [0.3, 0.4], ["-40%", "-55%"]);
  const Parralaxvalue1Spring = useSpring(ParralaxvalueNbr1, { damping: 100, stiffness: 100, mass: 1 })

  const ParralaxvalueNbr1Mobile = useTransform(scrollY, [0.2, 0.3], ["45%", "55%"]);
  const Parralaxvalue1SpringMbile = useSpring(ParralaxvalueNbr1Mobile, { damping: 100, stiffness: 100, mass: 1 })

  //2
  const ParralaxvalueNbr2 = useTransform(scrollY, [0.35, 0.45], ["-55%", "-60%"]);
  const ParralaxvalueNbr2Spring = useSpring(ParralaxvalueNbr2, { damping: 100, stiffness: 100, mass: 1 })

  const ParralaxvalueNbr2Mobile = useTransform(scrollY, [0.3, 0.4], ["45%", "55%"]);
  const ParralaxvalueNbr2SpringMobile = useSpring(ParralaxvalueNbr2Mobile, { damping: 100, stiffness: 100, mass: 1 })

  //scale 
  const scaleImg1 = useTransform(scrollY, [0.2, 0.35], [1, 1.2]);
  const scaleImg1Spring = useSpring(scaleImg1, { damping: 100, stiffness: 100, mass: 1 })

  return (
    <>
      <section id="section-expertise" className="pt-0 md:pt-20 xxl:pt-40 background-blue-edmond">
        <IntersectionObserver isReset={true} >
          <section id="section-anchor" className="w-screen h-screen-1/2 md:h-screen relative flex items-start justify-center bg-white md:bg-blue-edmond">
            {!isMobileOnly &&
              <motion.div style={{ y: ParralaxvalueSpring }} className="absolute z-20 left-0 bottom-0 h-screen-3/4 w-1/4" >
                <BackgroundImage
                  Tag="section"
                  className="w-full h-full"
                  fluid={data.EscalierImage.childImageSharp.fluid}
                  backgroundColor={`#CFDEE1`}
                >
                </BackgroundImage>
              </motion.div>
            }
            {isMobileOnly &&
              <div className="z-20 relative md:absolute py-10 md:pb-0 md:pt-24 flex flex-col items-start justify-start md:items-end md:justify-end w-10/12 md:w-1/2">
                <div>
                  <AppearAnimText>
                    <motion.h1
                      initial={{ opacity: 0 }}
                      variants={titleanimation}
                      className="uppercase sofia-semibold text-4xl xs:text-5xl md:text-6xl xxl:text-7xl leading-tight stroke">
                      <motion.div variants={textAppearFromLeft}><div className="md:-ml-40">Composer</div></motion.div>
                      <motion.div variants={textAppearFromLeft}><div className="md:-ml-20">des valeurs</div></motion.div>
                      <motion.div variants={textAppearFromLeft}><div>immobilières</div></motion.div>
                    </motion.h1>
                  </AppearAnimText>
                  <AppearAnimText>
                    <motion.div variants={paragraphAppear}>
                      <p className="md:w-1/2 text-left md:text-right mt-5 tracking-wide shadow-white text-lg xs:text-xl md:text-2xl xxl:text-2xl relative md:absolute z-10 sofia-bold uppercase text-grey-edmond">Plus de 200 projets<br></br>pour plus de 2Mds€
          <br></br>depuis 1999 </p>
                    </motion.div>
                  </AppearAnimText>
                </div>
              </div>
            }
            {!isMobileOnly &&
              <div className="relative md:absolute flex justify-center overflow-hidden bottom-0 right-0 mb-10 w-95perc h-screen-11/12">
                <div className="z-20 relative md:absolute py-10 md:pb-0 md:pt-24 flex flex-col items-start justify-start md:items-end md:justify-end w-9/12 md:w-2/3 desktop:w-1/2">
                  <div>
                    <AppearAnimText>
                      <motion.h1
                        initial={{ opacity: 0 }}
                        variants={titleanimation}
                        className="uppercase sofia-semibold text-4xl xs:text-5xl md:text-6xl xxl:text-7xl leading-tight stroke">
                        <motion.div variants={textAppearFromLeft}><div className="md:-ml-40">Composer</div></motion.div>
                        <motion.div variants={textAppearFromLeft}><div className="md:-ml-20">des valeurs</div></motion.div>
                        <motion.div variants={textAppearFromLeft}><div>immobilières</div></motion.div>
                      </motion.h1>
                    </AppearAnimText>
                    <AppearAnimText>
                      <motion.div variants={paragraphAppear}>
                        <p className="md:w-1/2 text-left md:text-right mt-5 tracking-wide shadow-white text-lg xs:text-xl md:text-2xl xxl:text-2xl relative md:absolute z-10 sofia-bold uppercase text-grey-edmond">Plus de 200 projets<br></br>pour plus de 2Mds€
          <br></br>depuis 1999 </p>
                      </motion.div>
                    </AppearAnimText>
                  </div>
                </div>
                <motion.div style={{ scale: scaleImg1Spring, transformOrigin: "0.5 0.5" }} className="w-full h-full top-0 left-0">
                  <BackgroundImage
                    Tag="section"
                    className="w-full h-full"
                    fluid={data.ComposerImage1.childImageSharp.fluid}
                    backgroundColor={`#CFDEE1`}
                  >
                  </BackgroundImage>
                </motion.div>
              </div>
            }
            <div className="absolute z-10 h-20 bottom-0 left-0 w-full bg-white"></div>
          </section>
        </IntersectionObserver>
        <section className="w-full min-height-screen flex flex-col items-center justify-center desktop:flex-row desktop:items-end desktop:justify-start bg-white">
          <div className="relative desktop:absolute z-20 desktop:h-screen-3/4 ml-0 desktop:ml-20 gap-10 text-sof text-right w-10/12 desktop:w-3/5 flex flex-col-reverse desktop:flex-row items-end desktop:items-start">
            <div className="relative z-30 w-full mt-0 desktop:mt-10 xxl:mt-0 desktop:w-2/5">
              {!isMobile && <motion.div style={{ y: Parralaxvalue1Spring }} className="transform -translate-y-1/2 absolute right-0 top-0 -mr-40 stroke-bronze text-ultraxl sofia-bold">1</motion.div>}
              <IntersectionObserver isReset={true} >
                <AppearAnimText>
                  <motion.h2 variants={textAppearFromLeft} className="text-bronze-edmond sofia-bold uppercase text-xs desktop:text-sm xxl:text-sm">INVESTISSEMENT</motion.h2>
                  <motion.h2 variants={textAppearFromLeft} className="text-bronze-edmond sofia-bold text-4xl desktop:text-2xl xxl:text-5xl uppercase">S’ENGAGER</motion.h2>
                  <motion.div variants={paragraphAppear} className="mt-5 text-dark-edmond sofia-light text-base xs:text-lg desktop:text-sm xxl:text-sm leading-7  desktop:leading-9">
                    {/* Fort de son expérience et de son réseau de partenaires,
                  Coignet Investment Management est reconnu pour
            <span className="text-darkblue-edmond sofia-bold"> sa fine connaissance du marché immobilier parisien, </span>
          sa capacité à <span className="text-darkblue-edmond sofia-bold">identifier les belles opportunités </span>
          immobilières et à <span className="text-darkblue-edmond sofia-bold">réaliser les opérations avec vélocité. </span>
          Il structure financièrement ces opérations
          en agissant pour son propre compte
et pour celui de co-investisseurs. */}
                    <span className="text-darkblue-edmond sofia-bold">S’appuyant sur une expertise reconnue et une capacité à identifier les opportunités, </span>
Coignet IM structure des opérations autour de 2 stratégies d’investissement (core+ et value-add)
pour son propre compte et celui de ses co-investisseurs, dans un engagement total.
</motion.div>
                </AppearAnimText>
              </IntersectionObserver>
            </div>
            <motion.div style={{ y: !isMobile ? ParralaxvalueSpring2 : ParralaxvalueSpring2Mobile }} className="relative h-screen-1/2 desktop:h-full w-3/4 desktop:w-3/6 mb-10 desktop:mb-20 mt-5">
              {isMobile && <motion.div style={{ y: Parralaxvalue1SpringMbile }} className="z-20 transform mb-10 translate-y-1/2 absolute right-0 bottom-0 stroke-bronze text-ultraxlSM sofia-bold">1</motion.div>}
              <BackgroundImage
                Tag="section"
                className="w-full h-full"
                fluid={data.EngagerImage.childImageSharp.fluid}
                backgroundColor={`#CFDEE1`}
              >
              </BackgroundImage>
            </motion.div>
          </div>
          {!isMobile && <div className="relative h-screen-3/4 ml-20 gap-10 text-sof text-right w-2/3 flex flex-row"></div>}
          <div className="relative py-20 right-0 w-10/12 desktop:w-3/5 flex gap-10 flex-col">
            <motion.div style={{ y: ParralaxvalueSpring3 }} className="relative pt-0 mb-10 desktop:mb-0 desktop:pt-20 z-10 h-screen-1/2 w-3/4 desktop:w-full">
              {isMobile && <motion.div style={{ y: ParralaxvalueNbr2SpringMobile }} className="z-20 mb-10 transform translate-y-1/2 absolute left-0 bottom-0 stroke-blue text-ultraxlSM sofia-bold">2</motion.div>}
              <BackgroundImage
                Tag="section"
                className="w-full h-full"
                fluid={data.ValoriserImage.childImageSharp.fluid}
                backgroundColor={`#CFDEE1`}
              >
              </BackgroundImage>
            </motion.div>
            <div className="flex flex-col relative z-30 desktop:h-screen-1/2 justify-center items-start text-left w-full">
              <div className="relative mt-0 desktop:mt-10 xxl:mt-0 ml-0 desktop:ml-48 w-full desktop:w-1/2">
                <IntersectionObserver isReset={true} >
                  <AppearAnimText>
                    {!isMobile && <motion.div style={{ y: ParralaxvalueNbr2Spring }} className="mt-16 transform -translate-y-1/2 absolute left-0 top-0 -ml-40 stroke-blue text-ultraxl sofia-bold">2</motion.div>}
                    <motion.h2 variants={textAppearFromLeft} className="text-bronze-edmond sofia-bold uppercase desktop:text-sm xxl:text-sm">Asset Management</motion.h2>
                    <motion.h2 variants={textAppearFromLeft} className="text-bronze-edmond sofia-bold text-4xl desktop:text-2xl xxl:text-5xl uppercase">Valoriser</motion.h2>
                    <motion.div variants={paragraphAppear} className="mt-5 text-dark-edmond sofia-light text-base xs:text-lg desktop:text-sm xxl:text-sm  leading-7 md:leading-9">
                      {/*Coignet Investment Management<span className="text-darkblue-edmond sofia-bold"> optimise la performance</span> de chaque
actif immobilier et <span className="text-darkblue-edmond sofia-bold">garantit sa valeur </span>
en élaborant <span className="text-darkblue-edmond sofia-bold">la meilleure stratégie. </span>
 Il restructure les biens, il les adapte
aux nouveaux usages et il sélectionne
les locataires. <span className="text-darkblue-edmond sofia-bold">Détenteur d’un portefeuille</span>
                      <span className="text-darkblue-edmond sofia-bold">d’actifs immobiliers de qualité,</span> il optimise
son rendement locatif dans <span className="text-darkblue-edmond sofia-bold">une stratégie </span>
                      <span className="text-darkblue-edmond sofia-bold">immobilière de long terme.</span> */}
                      <span className="text-darkblue-edmond sofia-bold">Fort de son expérience et de son réseau de partenaires, </span> Coignet IM dispose d’une équipe dédiée qui imagine en face de chaque actif immobilier
                      la stratégie de valorisation adéquate, s’adaptant aux nouveaux usages et au service des utilisateurs.
                    </motion.div>
                  </AppearAnimText>
                </IntersectionObserver >
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  )
}

const Valeurs = ({ data, scrollYProgress }) => {

  const [navigatorState, setNavigatorState] = useState(null);

  useEffect(() => {
    const navigatorGlobal = typeof navigator !== 'undefined' && navigator
    setNavigatorState(navigatorGlobal);

  }, [navigatorState]);

  const scrollY = scrollYProgress;
  let isFirefox = navigatorState !== null && navigator.userAgent.toLowerCase().indexOf('firefox');

  const Parralaxvalue = useTransform(scrollY, [0.5, 0.65], [0, -100]);
  const ParralaxvalueSpring = useSpring(Parralaxvalue, { damping: 100, stiffness: 100, mass: 1 });

  const Parralaxvalue2 = useTransform(scrollY, [0.6, 0.75], [100, 0]);
  const ParralaxvalueSpring2 = useSpring(Parralaxvalue2, { damping: 100, stiffness: 100, mass: 1 });

  const scaleImg1 = useTransform(scrollY, [0.6, 0.8], [1, 1.2]);
  const scaleImg1Spring = useSpring(scaleImg1, { damping: 100, stiffness: 100, mass: 1 })

  return (
    <>
      <section id="section-valeurs" className="relative mt-0 desktop:mt-40">
        <div className={`absolute z-50 transform -translate-x-1/2 -translate-y-1/2 ${!isMobileOnly ? "w-3/4" : "w-full"} left-0 top-0`}>
          <LottieWrapper thresholdParam="0.3" anim="LogoOOBeige" />
        </div>
        <IntersectionObserver isReset={true} >
          <section className="w-full min-h-screen relative flex items-start justify-start">
            <div className="absolute z-50 w-full h-screen flex justify-center items-center md:justify-end md:items-end">
              <div className={`flex flex-col items-center md:items-end justify-end backdrop-blur-strong w-full ${isFirefox > -1 ? "bg-white bg-opacity-50" : ""}`}>
                <div className="w-10/12 md:w-3/5 desktop:w-1/2 py-10 xs:py-20 md:p-10 desktop:p-20" >
                  <AppearAnimText>
                    <motion.h1
                      initial={{ opacity: 0 }}
                      variants={titleanimation}
                      className="uppercase whitespace-no-wrap sofia-semibold text-4xl xs:text-5xl md:text-6xl xxl:text-7xl leading-tight stroke">
                      <motion.div variants={textAppearFromLeft}>COSIGNER<br></br></motion.div>
                      <motion.div variants={textAppearFromLeft}>SA DIFFÉRENCE</motion.div>
                    </motion.h1>
                    <motion.p variants={paragraphAppear} className="mt-5 text-dark-edmond sofia-light text-base xs:text-lg md:text-sm  xxl:text-sm leading-7  md:leading-9">
                      {/* Pour révéler de nouveaux actifs et en maximiser la valeur, <span className="sofia-bold">Coignet IM s’appuie sur sa réactivité
et sa capacité à mobiliser les bons partenaires.</span> Dans un marché qui se structure et se financiarise,
il représente <span className="sofia-bold">une alternative</span> pour les investisseurs en quête de confiance et de rapidité dans la prise
de décision et d’exécution. Avec eux, il signe <span className="sofia-bold">des opérations immobilières créatrices de valeur
dans toutes les classes d’actifs.</span> */}
Pour révéler de nouveaux actifs, <span className="sofia-bold">Coignet IM s’appuie sur son agilité et sa capacité à mobiliser les bons partenaires. </span>
Dans un marché qui se structure et se financiarise, <span className="sofia-bold">il représente une alternative pour les investisseurs en quête de confiance et de
rapidité</span> dans la prise de décision et d’exécution.
Avec eux, il signe des opérations immobilières créatrices de valeur, dans la durée.
                    </motion.p>
                  </AppearAnimText>
                </div>
              </div>
            </div>
            <motion.div style={{ y: !isMobile ? ParralaxvalueSpring : 0 }} className="absolute left-0 top-0 z-40 w-full md:w-3/4 h-screen desktop:h-screen-150">
              <BackgroundImage
                Tag="section"
                className="w-full h-screen desktop:h-screen-150"
                fluid={data.CosignerImage.childImageSharp.fluid}
                backgroundColor={`#CFDEE1`}
              >
              </BackgroundImage>
            </motion.div>
            {!isMobileOnly && <div className="absolute right-0 bottom-0 z-40 w-1/4 h-screen flex items-end justify-end"><div className="h-screen-11/12 bg-bronze-edmond w-full"></div></div>}
          </section>
        </IntersectionObserver>
        <section id="section-coconstruire" className="background-bluelight-edmond z-30 w-full md:min-h-screen relative flex items-start justify-center md:justify-start">
          <div className="md:mt-32 xxl:mt-64 w-10/12 md:w-full">
            <div className="relative desktop:mt-40 z-40 flex flex-row w-full justify-between text-sm xs:text-base desktop:text-xl xxl:text-2xl sofia-bold uppercase text-white mt-10">
              <div className="md:w-1/3 flex items-start justify-start md:pl-20 md:ml-5">PERFORMANCE</div>
              <div className="md:w-1/3 flex items-center justify-center">discrétion</div>
              <div className="md:w-1/3 flex items-end justify-end md:pr-20 md:mr-5">agilité</div>
            </div>
            <div className="relative my-10 xs:my-20 z-20 flex flex-row w-full">
              <div className={`relative ${isBrowser ? "md:absolute" : ""} z-40 flex items-center justify-center left-0 top-0 w-full h-screen-75/100`}>
                <div className="flex items-center justify-center flex-col w-full md:w-10/12">
                  <div className="flex w-full md:w-4/5 xxl:w-3/4 items-center justify-center text-center">

                    <div className="flex-row break-normal items-center justify-center">
                      <IntersectionObserver isReset={true} >
                        <AppearAnimText>
                          {isBrowser &&
                            <motion.h1
                              initial={{ opacity: 0 }}
                              variants={textAppearFromLeft}
                              className=" sofia-semibold text-left md:text-6xl xxl:text-7xl leading-tight stroke break-normal uppercase">CO-construire
                        </motion.h1>
                          }
                          {isMobile &&
                            <>
                              <motion.h1
                                initial={{ opacity: 0 }}
                                variants={textAppearFromLeft}
                                className="whitespace-no-wrap sofia-semibold text-left md:text-center text-4xl xs:text-5xl md:text-6xl xxl:text-7xl leading-tight stroke break-normal uppercase">CO-construire<br></br>la confiance
                        </motion.h1>
                              <div className="w-full flex items-center justify-center"><LogoOO className="w-32 my-5 h-full" style={{ stroke: '#000', strokeWidth: "2" }} /></div>
                            </>
                          }
                          <div className="relative flex flex-col md:flex-row items-center md:items-start justify-center">
                            <motion.div variants={paragraphAppear} className="w-full xxl:w-1/3 md:pr-10 text-center desktop:text-right md:mt-5 text-dark-edmond sofia-light text-base xs:text-lg md:text-sm md:text-sm xxl:text-sm leading-7  md:leading-9">
                              Référence sur le marché parisien depuis 20 ans, <span className="sofia-bold"> Coignet IM est une maison de confiance. Cette réputation se traduit notamment par la fidélité de ses co-investisseurs et partenaires. </span>
Ensemble, ils mènent des opérations sur-mesure avec discrétion et transparence. <span className="sofia-bold">Structure à taille humaine et attachée à la parole donnée, Coignet IM entretient une relation dans la durée.</span><br></br> En créant une valeur partagée et durable.
                              {/* Référence sur le marché parisien depuis 20 ans,
                              Coignet Investment Management est
                    <span className="sofia-bold"> une maison de confiance.</span> Cette réputation
                    se traduit par la fidélité de ses co-investisseurs. Ensemble, ils mènent des opérations <span className="sofia-bold"> sur-mesure avec discrétion et transparence. </span>
                    Structure à taille humaine et attachée à la parole donnée, <span className="sofia-bold"> Coignet Investment Management entretient une relation dans la durée. </span> */}
                            </motion.div>
                            {isBrowser &&
                              <div className="flex-inline justify-between">
                                <LogoOO className="w-10 md:w-32 xxl:w-56 my-5 h-full" style={{ stroke: '#000', strokeWidth: "2" }} />
                                <motion.h1 variants={textAppearFromRight} className="whitespace-no-wrap sofia-semibold text-left md:text-6xl xxl:text-7xl leading-tight stroke break-normal uppercase">la confiance</motion.h1>
                              </div>
                            }
                          </div>
                        </AppearAnimText>
                      </IntersectionObserver>
                    </div>
                  </div>
                </div>
                {isBrowser &&
                  <div className="absolute bottom-0 md:mb-0 xxl:mb-10 z-40 flex flex-row w-full desktop:text-xl xxl:text-2xl sofia-bold uppercase text-white">
                    <div className="w-1/2 flex items-start justify-start pl-20 ml-5">engagement</div>
                    <div className="w-1/2 flex items-end justify-end pr-20 mr-5">transparence</div>
                  </div>
                }
              </div>
              {isBrowser &&
                <>
                  <div className="w-1/2"></div>
                  <motion.div style={{ y: ParralaxvalueSpring2 }} className="relative w-1/2 h-full mr-20 overflow-hidden">
                    <motion.div style={{ scale: scaleImg1Spring, transformOrigin: "0.5 0.5" }} className="w-full h-full top-0 left-0">
                      <BackgroundImage
                        Tag="section"
                        className="w-full h-screen z-20"
                        fluid={data.CoconstruireImage.childImageSharp.fluid}
                        backgroundColor={`#efe8df`}
                      >
                      </BackgroundImage>
                    </motion.div>
                  </motion.div>
                </>
              }
            </div>
            {isMobile &&
              <div className="relative mt-10 bottom-0 mb-10 z-40 flex flex-row justify-between w-full text-sm xs:text-base md:text-2xl sofia-bold uppercase text-white">
                <div className="md:w-1/3 flex items-start justify-start md:pl-20 md:ml-5">engagement</div>
                <div className="md:w-1/3 flex items-center justify-center"></div>
                <div className="md:w-1/3 flex items-end justify-end md:pr-20 md:mr-5">transparence</div>
              </div>
            }
          </div>
          <div className="absolute h-full w-full z-10">
            <BackgroundImage
              Tag="section"
              className="w-full h-full"
              fluid={data.CoconstruireImageBg.childImageSharp.fluid}
              backgroundColor={`#efe8df`}
            >
            </BackgroundImage>
          </div>
        </section>
      </section>
    </>
  )
}

const Realisations = ({ data, filterTags, filterProjects }) => {

  const [tagsState, setTagsState] = useState(filterTags);
  const [MaxLenght, setMaxLength] = useState(filterProjects);
  const [filterActive, setFilterActive] = useState(false);
  const [DocumentState, setDocumentState] = useState();
  const [WindowHeight, setWindowHeight] = useState();

  const dispatch = useDispatch();

  let tags = [];

  data.allMarkdownRemark.edges.forEach((edge) => {
    tags = tags.concat(edge.node.frontmatter.tags);
  })

  const sortedBy = {
    'Mixte': 0,
    'Habitation': 1,
    'Bureaux': 2,
    'Commerce': 3,
    'Hôtellerie': 4,
    'Autre': 5,
  }

  let uniqTags = Array.from([...new Set(tags)]).sort((a, b) => sortedBy[a] - sortedBy[b]);

  function ToogleTag(tagname) {

    let scrollValueProjects = document.getElementById("section-réalisations").offsetTop;
    let scrollValueOff = document.getElementById("wrapperProjectsLast").offsetTop;

    window.scrollTo({ top: scrollValueProjects + scrollValueOff, behavior: 'smooth' });

    setTagsState(filterTags);

    setMaxLength(5);

    tagsState.some(r => tagname.includes(r)) === false && setTagsState(tagsState => [...tagsState, tagname]);
    tagsState.some(r => tagname.includes(r)) === true && setTagsState(tagsState => tagsState.filter((e) => (e !== tagname)));
    dispatch({ type: 'SetProjects', value: MaxLenght });

  }

  function ResetTag() {

    setMaxLength(5);
    setTagsState([]);

    let scrollValueProjects = document.getElementById("section-réalisations").offsetTop;
    let scrollValueOff = document.getElementById("wrapperProjectsLast").offsetTop;

    window.scrollTo({ top: scrollValueProjects + scrollValueOff, behavior: 'smooth' });

    dispatch({ type: 'SetProjects', value: MaxLenght });
  }

  useEffect(() => {
    const windowGlobal = typeof window !== 'undefined' && window
    const documentGlobal = typeof document !== 'undefined' && document

    setWindowHeight(windowGlobal.innerHeight);
    setDocumentState(documentGlobal);

    tagsState.length > 0 && dispatch({ type: 'SetTags', value: tagsState });
    tagsState.length === 0 && dispatch({ type: 'SetTags', value: [] });

  }, [tagsState, dispatch, filterActive]);

  const projectThumbAnim = {
    initial: {
      opacity: 0,
      scale: 0.8,
    },
    enter: {
      opacity: 1,
      scale: 1,
    },
    exit: {
      opacity: 0,
      transition: {
        type: "tween",
        duration: 0.3,
      }
    }
  }

  const thumbAnimThumb = {
    rest: { scale: 1 },
    hover: {
      scale: 1.05,
    }
  };

  const thumbAnimWhite = {
    rest: { opacity: 1 },
    hover: {
      opacity: 0,
    }
  };

  const descrAnimThumb = {
    rest: {
      x: 0,
      y: 10,
      opacity: 0,
      duration: 0.4,
      type: "tween"
    },
    hover: {
      opacity: 1,
      x: "35%",
      y: 0,
      transition: {
        type: "spring",
        damping: 20,
        mass: 1,
        duration: 0.7,
      }
    }
  };

  const UpdateMaxLenght = () => {
    setMaxLength(MaxLenght + 6);
    dispatch({ type: 'SetProjects', value: MaxLenght + 6 });
    window.setTimeout(() => calculateScroll(), 500);
  }

  function calculateScroll() {
    let documentScope = DocumentState;
    let windowScope = WindowHeight;

    let MainContentHeight = (documentScope && windowScope) !== undefined && (document.getElementById("mainIndex").clientHeight - windowScope);
    let presentationElScrollValue = (documentScope && windowScope) !== undefined && (documentScope.getElementById("section-presentation").clientHeight / MainContentHeight) * 100 / 100;
    let groupeElScrollValue = (documentScope && windowScope) !== undefined && (documentScope.getElementById("section-groupe").clientHeight / MainContentHeight) * 100 / 100;
    let expertiseElScrollValue = (documentScope && windowScope) !== undefined && (documentScope.getElementById("section-expertise").clientHeight / MainContentHeight) * 100 / 100;
    let valeursElScrollValue = (documentScope && windowScope) !== undefined && (documentScope.getElementById("section-valeurs").clientHeight / MainContentHeight) * 100 / 100;
    let réalisationsElScrollValue = (documentScope && windowScope) !== undefined && (documentScope.getElementById("section-réalisations").clientHeight / MainContentHeight) * 100 / 100;
    let contactElScrollValue = (documentScope && windowScope) !== undefined && (documentScope.getElementById("section-contact").clientHeight / MainContentHeight) * 100 / 100;

    let value1 = (presentationElScrollValue + groupeElScrollValue);
    let value2 = (presentationElScrollValue + groupeElScrollValue + expertiseElScrollValue);
    let value3 = (presentationElScrollValue + groupeElScrollValue + expertiseElScrollValue + valeursElScrollValue);
    let value4 = (presentationElScrollValue + groupeElScrollValue + expertiseElScrollValue + valeursElScrollValue + réalisationsElScrollValue);

    dispatch({ type: 'SetScrollValue', value: [0, value1, value2, value3, value4, 1] });
    console.log([0, value1, value2, value3, value4, 1]);

  }

  return (
    <>
      <AnimateSharedLayout type="crossfade">
        <motion.section id="section-réalisations" className="background-bluelight-edmond">
          <section className="background-bluelight-edmond pt-20 xxl:pt-32 pb-20 z-30 w-full relative flex flex-col items-center md:items-start justify-center md:justify-start">
            <div className="overflow-hidden w-full h-screen absolute top-0 left-0">
            <div className={`overflow-hidden  transform ${!isMobileOnly ? "translate-x-1/3 translate-y-3/4 w-10/12" : "-translate-x-1/2 w-full"} absolute z-10 right-0 top-0`}>
              <div className=""><LottieWrapper thresholdParam={!isMobileOnly ? "0.3" : "0"} anim="LogoOO" /></div>
            </div>
            </div>
            <motion.div className="w-10/12 md:w-11/12">
              <IntersectionObserver isReset={true} >
                <AppearAnimText>
                  <motion.h1
                    initial={{ opacity: 0 }}
                    variants={titleanimation}
                    className="relative z-20 text-right w-full uppercase sofia-semibold text-4xl xs:text-5xl md:text-6xl xxl:text-7xl leading-tight stroke-blue-thin">
                    <motion.div variants={textAppearFromLeft}>Collectionner<br></br></motion.div>
                    <motion.div variants={textAppearFromLeft}>les belles<br></br></motion.div>
                    <motion.div variants={textAppearFromLeft}>adresses</motion.div>
                  </motion.h1>
                </AppearAnimText>
              </IntersectionObserver>
            </motion.div>
            <motion.section className="w-full flex flex-col items-center justify-center" id="wrapperProjectsLast">
              <IntersectionObserverSticky>
                <div className="w-full md:w-11/12 pt-5 md:pt-0 flex select-none flex-wrap items-center justify-center md:flex-row md:justify-start md:items-start gap-2 md:gap-5">
                  <div onClick={() => ResetTag()} className={`inline-block mx-2 md:mx-5 transition-opacity duration-300 hover:opacity-100 py-2 md:py-4 ${filterTags.length === 0 ? "tag-active opacity-100" : "tag-link opacity-50"} relative cursor-pointer sofia-bold text-sm tracking-wide text-grey-edmond uppercase`}>Tous</div>
                  {uniqTags.map((tag) => (
                    <div onClick={() => ToogleTag(tag)} className={`inline-block mx-2 md:mx-5 transition-opacity duration-300 hover:opacity-100 py-2 md:py-4 ${filterTags.includes(tag) ? "tag-active opacity-100" : "tag-link opacity-50"} cursor-pointer relative sofia-bold text-sm tracking-wide text-grey-edmond uppercase`} key={tag}>{tag}</div>
                  ))}
                </div>
              </IntersectionObserverSticky>
              <section className="relative z-30 w-full md:w-11/12 pt-10 flex flex-row flex-wrap" >
                <AnimatePresence>
                  {data.allMarkdownRemark.edges.filter(edges => edges.node.frontmatter.tags.some(r => filterTags.includes(r)) || filterTags.length === 0).map((realisation, index, array) =>
                    (index <= MaxLenght &&
                      <motion.div
                        initial="initial"
                        animate="enter"
                        key={realisation.node.frontmatter.titre}
                        variants={projectThumbAnim}
                        exit="exit"
                        transition={{ type: "spring", bounce: 0.25, duration: 0.15, damping: 15, delay: 0.1 }}
                        className="w-full md:w-1/2 desktop:w-1/3 relative mb-20"
                        whileHover={{ zIndex: "30" }}
                        layout
                      >
                        <motion.div whileHover="hover" className="left-0 pr-20 top-0 z-10 w-full h-screen-1/2 md:h-screen-1/3 opacity-75">
                          {realisation.node.frontmatter.thumbnail !== null &&
                            <Link to={realisation.node.frontmatter.path}>
                              <motion.div initial="rest" whileHover="hover" variants={thumbAnimThumb} transition={{ type: "spring", damping: 10, mass: 0.5, duration: 0.3 }} className="relative transition-all cursor-pointer w-full h-full">
                                <motion.div variants={thumbAnimWhite} style={{ filter: "saturate(35%)" }} className="absolute z-10 w-full h-full top-0 left-0">
                                  <div className="absolute z-20 bg-white opacity-25 w-full h-full"></div>
                                  <BackgroundImage
                                    Tag="section"
                                    className="w-full h-full cursor-pointer"
                                    fluid={realisation.node.frontmatter.thumbnail.childImageSharp.fluid}
                                    backgroundColor={`#5B6B74`}
                                  >
                                  </BackgroundImage>
                                </motion.div>
                                <div className="absolute w-full h-full top-0 left-0 ">
                                  <BackgroundImage
                                    Tag="section"
                                    className="w-full h-full cursor-pointer"
                                    fluid={realisation.node.frontmatter.thumbnail.childImageSharp.fluid}
                                    backgroundColor={`#5B6B74`}
                                  >
                                  </BackgroundImage>
                                </div>
                                {!isMobile &&
                                  <motion.div initial="rest" variants={descrAnimThumb} className="bg-white p-5 bg-opacity-75 transform translate-x-1/2 pointer-events-none absolute right-0 top-0 z-20 mt-10 flex-col text-verydark-edmond items-center justify-center">
                                    <h1 className="sofia-bold text-lg tracking-widest uppercase">{realisation.node.frontmatter.titre}</h1>
                                    <p className="sofia-bold text-sm tracking-widest">{realisation.node.frontmatter.adresse1}</p>
                                    <p className="sofia-bold text-sm tracking-widest">{realisation.node.frontmatter.adresse2}</p>
                                  </motion.div>
                                }
                                {isMobile &&
                                  <motion.div className="bg-white p-5 bg-opacity-75 transform translate-x-20 pointer-events-none absolute right-0 top-0 z-20 mt-10 flex-col text-verydark-edmond items-center justify-center">
                                    <h1 className="sofia-bold text-sm tracking-widest uppercase">{realisation.node.frontmatter.titre}</h1>
                                    <p className="sofia-bold text-xs tracking-widest">{realisation.node.frontmatter.adresse1}</p>
                                    <p className="sofia-bold text-xs tracking-widest">{realisation.node.frontmatter.adresse2}</p>
                                  </motion.div>
                                }
                              </motion.div>
                            </Link>
                          }
                        </motion.div>
                      </motion.div>
                    )
                  )}
                </AnimatePresence>
              </section>
              <AnimatePresence>
                {data.allMarkdownRemark.edges.filter(edges => edges.node.frontmatter.tags.some(r => filterTags.includes(r)) || filterTags.length === 0).length > (MaxLenght + 1) &&
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    layout
                    className="result-btn inline-block relative uppercase cursor-pointer select-none p-5 mt-0 sofia-bold text-sm tracking-wide text-grey-edmond" onClick={() => UpdateMaxLenght()}>VOIR PLUS DE RÉSULTATS
              </motion.div>
                }
              </AnimatePresence>
            </motion.section>
          </section>
        </motion.section>
        <Footer />
      </AnimateSharedLayout>
    </>
  )
}

export const data = graphql`
    query {
          confianceImage: file(relativePath: {eq: "home/confiance.jpg" }) {
          childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
        }
      },
      RevelerImage1: file(relativePath: {eq: "home/reveler-1.jpg" }) {
          childImageSharp {
          fluid(quality: 90, maxWidth: 757) {
          ...GatsbyImageSharpFluid_withWebp
        }
        }
      },
      RevelerImage2: file(relativePath: {eq: "home/reveler-2.jpg" }) {
          childImageSharp {
          fluid(quality: 90, maxWidth: 757) {
          ...GatsbyImageSharpFluid_withWebp
        }
        }
      }
      ComposerImage1: file(relativePath: {eq: "home/composer_des_valeurs.jpg" }) {
          childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
        }
      }
      EscalierImage: file(relativePath: {eq: "home/escaliers.jpg" }) {
          childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
        }
      }
      EngagerImage: file(relativePath: {eq: "home/engager.jpg" }) {
          childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
        }
      }
      ValoriserImage: file(relativePath: {eq: "home/valoriser.jpg" }) {
          childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
        }
      }
      CosignerImage: file(relativePath: {eq: "home/cosigner.jpg" }) {
          childImageSharp {
          fluid(quality: 90, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
        }
      }
      CoconstruireImageBg: file(relativePath: {eq: "home/bg-coconstruire.jpg" }) {
          childImageSharp {
          fluid(quality: 90, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
        }
      }
      CoconstruireImage: file(relativePath: {eq: "home/coconstruire.jpg" }) {
          childImageSharp {
          fluid(quality: 90, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
        }
      }
      allMarkdownRemark(sort: {fields: frontmatter___ordre, order: ASC}) {
          edges {
          node {
          html
            id
            frontmatter {
          titre
              path
              ordre
              tags
              adresse1
              adresse2
              gallerie {
                image {
                  childImageSharp {
                    fluid(quality: 95, maxWidth: 1500) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              thumbnail {
                childImageSharp {
                  fluid(quality: 95, maxWidth: 1500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
  }
  `

//   allPrismicRealisations
//   {
//    edges {
//      node {
//        id
//        first_publication_date
//        tags
//        data {
//          titre
//          {
//            text
//          }
//          adresse
//          {
//            text
//          }
//          adresse2
//          {
//            text
//          }
//          image {
//            localFile {
//              childImageSharp {
//                fluid(maxWidth: 1500) {
//                  ...GatsbyImageSharpFluid_withWebp
//                }
//              }
//            }
//          }
//      }
//    }
//  }
// }


export default connect(state => ({
  isIntro: state.IsIntro.isIntro,
  projectShow: state.ProjectViewer.Show,
  project: state.ProjectViewer.project,
  filterTags: state.FilterTags.filtertags,
  filterProjects: state.FilterProjects.filterprojects,
}), null)(IndexPage);
