import React, { useEffect, useState, useRef } from "react";
import { useIntersection } from "react-use";
import { motion } from 'framer-motion';

export const IntersectionContext = React.createContext({ inView: true });

export const IntersectionObserverSticky = ({
  children,
  reset = true // if value set to true - observed element will reappear every time it shows up on the screen
}) => {
  const [inView, setInView] = useState(false);
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null, // viewport
    threshold: [0,1]
  });

  useEffect(() => {
    const inViewNow = intersection && intersection.intersectionRatio === 0;
    const inNotInView = intersection && intersection.intersectionRatio === 1;

    if (inViewNow) {
      return setInView(true);
    } else if (inNotInView) {
      return setInView(false);
    }
  }, [intersection, reset]);

  const ParentAnimSticky = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        type:"tween",
        duration: 0.5,
      }
    },
    exit: {
      opacity: 1,
      y: 0,
      duration: 1.5,
    },
  };

  return (
    <IntersectionContext.Provider value={{ inView }}>
      <div ref={intersectionRef} className="h-1" ></div>
      <motion.div 
      className="w-full pb-5 md:pb-0 pt-16 flex items-center justify-center sticky top-0 z-40"
      >
        <div
          id="filterWrapper" 
          className="relative z-50 w-11/12"
          >
          {children}
        </div>
        <motion.div 
        initial={{ opacity:0 }}
        variants={ParentAnimSticky}
        animate={inView ? "show" : "hidden"}
        className="top-0 left-0 absolute w-full h-full background-bluelight-edmond">
        </motion.div>
      </motion.div>
    </IntersectionContext.Provider>
  );
};