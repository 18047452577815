import * as React from "react"

function LogoMobile(props) {
  return (
    <svg width={33.676} height={27.491} viewBox="0 0 33.676 27.491" {...props}>
      <g>
        <path
          d="M33.676 13.725a13.73 13.73 0 01-3.973 9.728 13.542 13.542 0 01-9.714 4.039 13.406 13.406 0 01-1.389-.076 14.417 14.417 0 009.476-12.553c.031-.42.045-.792.045-1.137s-.014-.713-.048-1.147a7.928 7.928 0 00-8.043-7.446 8.3 8.3 0 00-3.325.672l-.024.014-.272.117a7.629 7.629 0 00-.482.255 8.486 8.486 0 00-4.052 7.536 8.55 8.55 0 004.007 7.533c-.024.007-.052.011-.079.017a5.948 5.948 0 01-.372.1c-.038.01-.079.021-.117.028a7.485 7.485 0 01-.479.086c-.031.007-.062.007-.093.014a7.84 7.84 0 01-.414.041l-.138.01a7.864 7.864 0 01-1.217-.014 13.48 13.48 0 01-.365-.045 7.333 7.333 0 01-6.223-6.7v-.007c-.031-.4-.045-.737-.045-1.061s.014-.661.045-1.065a13.662 13.662 0 0127.287 1.065z"
        />
        <path
          d="M27.332 13.725c0 .324-.014.676-.045 1.079a13.621 13.621 0 01-10.534 12.335 13.875 13.875 0 01-3.1.352 13.746 13.746 0 010-27.491 14.165 14.165 0 011.43.072.013.013 0 01-.01 0l-.1.041c-.141.052-.279.1-.417.159l-.186.079c-.107.045-.217.089-.324.138-.069.031-.134.066-.2.1-.1.048-.2.093-.293.141l-.21.107c-.093.048-.183.1-.276.148-.069.038-.141.076-.21.117-.089.048-.176.1-.265.152-.069.041-.138.086-.207.127-.086.055-.172.107-.255.162s-.138.089-.2.134a13.64 13.64 0 00-.251.176c-.065.045-.131.089-.193.134l-.255.193-.176.134c-.1.079-.193.155-.286.234-.045.038-.09.072-.131.11-.135.114-.269.231-.4.351-.045.041-.089.086-.134.127-.086.083-.169.162-.252.245-.055.052-.107.107-.162.162-.072.076-.145.148-.213.224a13.432 13.432 0 00-.52.586l-.186.227-.155.2-.176.231c-.059.076-.1.134-.148.2-.055.083-.114.162-.169.245l-.135.2q-.088.129-.165.258c-.041.066-.086.131-.127.2-.055.089-.11.186-.165.279l-.107.186a15.894 15.894 0 00-.255.479c-.083.158-.158.321-.234.482-.028.059-.052.117-.079.176-.048.107-.1.213-.141.324-.031.069-.055.138-.086.207l-.117.3c-.028.072-.052.145-.079.221-.034.1-.069.2-.1.3-.028.076-.052.155-.076.231l-.09.3c-.021.076-.045.155-.065.234-.028.1-.055.2-.079.3a4.852 4.852 0 00-.058.238c-.024.1-.045.207-.069.314-.017.076-.031.155-.048.231-.021.11-.038.224-.059.334-.01.072-.024.148-.038.221-.017.124-.035.248-.048.372-.01.062-.021.127-.028.19-.021.186-.038.376-.052.565-.034.424-.048.782-.048 1.127s.014.7.048 1.144a9.406 9.406 0 00.317 1.589 7.836 7.836 0 007.767 5.9 7.668 7.668 0 004.09-1.072 8.548 8.548 0 004.025-7.564 8.517 8.517 0 00-3.956-7.5 7.734 7.734 0 012.185-.307 7.166 7.166 0 017.257 6.73c.011.411.024.752.024 1.08z"
        />
      </g>
    </svg>
  )
}

export default LogoMobile