import { motion, useSpring, useTransform, AnimatePresence, useViewportScroll } from 'framer-motion';
import {
  isBrowser,
  isMobileOnly
} from "react-device-detect";

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import LogoMobile from '../svg/logomobile';
import SvgBurger from '../svg/burger';
import SvgCroix from '../svg/croix';
import LogoGreyEdmond from '../svg/logo_grey';
import { useDispatch } from 'react-redux';

const Header = ({ isIntro, location, scrollProgressValue}) => {

  const [isAnimation, setIsAnimation] = useState(false);
  const [WindowHeight, setWindowHeight] = useState();
  const [DocumentState, setDocumentState] = useState();
  const { scrollY, scrollYProgress } = useViewportScroll();
  const [WindowScope, setWindowScope] = useState();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const dispatch = useDispatch();

  const [ValueSection1, setValueSection1] = useState(0.26);
  const [ValueSection2, setValueSection2] = useState(0.54);
  const [ValueSection3, setValueSection3] = useState(0.85);
  const [ValueSection4, setValueSection4] = useState(0.99);

  useEffect(() => {
    const windowGlobal = typeof window !== 'undefined' && window
    const documentGlobal = typeof document !== 'undefined' && document

    function setValuesScroll() {
      setWindowHeight(windowGlobal.innerHeight);

      
      let presentationElScrollValue = document.getElementById("section-presentation").clientHeight;
      let groupeElScrollValue = document.getElementById("section-groupe").clientHeight;
      let expertiseElScrollValue = document.getElementById("section-expertise").clientHeight;
      let valeursElScrollValue = document.getElementById("section-valeurs").clientHeight;
      let réalisationsElScrollValue = document.getElementById("section-réalisations").clientHeight;
      let contactElScrollValue = document.getElementById("section-contact").clientHeight;

      let MainContentHeight = (presentationElScrollValue + groupeElScrollValue + expertiseElScrollValue + valeursElScrollValue + réalisationsElScrollValue + contactElScrollValue) - window.innerHeight;
      let MainContentHeightTest = document.getElementById("mainIndex").clientHeight;

      let presentationElScrollValueCalculate =  (presentationElScrollValue / MainContentHeight) * 100 / 100;
      let groupeElScrollValueCalculate =  (groupeElScrollValue / MainContentHeight) * 100 / 100;
      let expertiseElScrollValueCalculate =  (expertiseElScrollValue / MainContentHeight) * 100 / 100;
      let valeursElScrollValueCalculate =  (valeursElScrollValue / MainContentHeight) * 100 / 100;
      let réalisationsElScrollCalculate =  (réalisationsElScrollValue / MainContentHeight) * 100 / 100;

      let value1 = (presentationElScrollValueCalculate + groupeElScrollValueCalculate);
      let value2 = (presentationElScrollValueCalculate + groupeElScrollValueCalculate + expertiseElScrollValueCalculate);
      let value3 = (presentationElScrollValueCalculate + groupeElScrollValueCalculate + expertiseElScrollValueCalculate + valeursElScrollValueCalculate);
      let value4 = (presentationElScrollValueCalculate + groupeElScrollValueCalculate + expertiseElScrollValueCalculate + valeursElScrollValueCalculate + réalisationsElScrollCalculate);

      setValueSection1(value1);
      setValueSection2(value2);
      setValueSection3(value3);
      setValueSection4(value4);
      console.log(value3);
    };

    setWindowHeight(windowGlobal.innerHeight);

    setDocumentState(documentGlobal);
    setWindowScope(windowGlobal);
    setValuesScroll();
    windowGlobal.addEventListener('resize', setValuesScroll);
    return () => (windowGlobal.removeEventListener('resize', setValuesScroll));
  }, [scrollProgressValue]);

  function toogleMenu(state) {
    setMenuIsOpen(!state);
  }

  let widthProgressBar = useTransform(scrollYProgress, [0, ValueSection1, ValueSection2, ValueSection3, ValueSection4, 1], [0, 0.21, 0.41, 0.61, 0.81, 1]);
  let widthProgressBarSpring = useSpring(widthProgressBar, { damping: 100, stiffness: 100, mass: 1 });

  const opacityGroupe = useTransform(widthProgressBar, [0, 0.01, 0.2, 0.21], [0.5, 1, 1, 0.5]);
  const opacityExpertise = useTransform(widthProgressBar, [0.2, 0.21, 0.4, 0.41], [0.5, 1, 1, 0.5]);
  const opacityValeurs = useTransform(widthProgressBar, [0.4, 0.41, 0.6, 0.61], [0.5, 1, 1, 0.5]);
  const opacityRealisations = useTransform(widthProgressBar, [0.6, 0.61, 0.8, 0.81], [0.5, 1, 1, 0.5]);
  const opacityContact = useTransform(widthProgressBar, [0.8, 0.81, 1], [0.5, 1, 1]);

  const yGroupe = useTransform(widthProgressBar, [0, 0.01, 0.2, 0.21], [5, 0, 0, 5]);
  const yExpertise = useTransform(widthProgressBar, [0.2, 0.21, 0.4, 0.41], [5, 0, 0, 5]);
  const yValeurs = useTransform(widthProgressBar, [0.4, 0.41, 0.6, 0.61], [5, 0, 0, 5]);
  const yRealisations = useTransform(widthProgressBar, [0.6, 0.61, 0.8, 0.81], [5, 0, 0, 5]);
  const yContact = useTransform(widthProgressBar, [0.8, 0.81, 1], [5, 0, 0]);

  const widthProgressBarMobile = useTransform(scrollYProgress, [0, ValueSection1, ValueSection2, ValueSection3, ValueSection4, 1], [0, 0.21, 0.41, 0.61, 0.81, 1]);

  const opacityGroupeMobile = useTransform(widthProgressBarMobile, [0, 0.01, 0.2, 0.21], [0.5, 1, 1, 0.5]);
  const opacityExpertiseMobile = useTransform(widthProgressBarMobile, [0.2, 0.21, 0.4, 0.41], [0.5, 1, 1, 0.5]);
  const opacityValeursMobile = useTransform(widthProgressBarMobile, [0.4, 0.41, 0.6, 0.61], [0.5, 1, 1, 0.5]);
  const opacityRealisationsMobile = useTransform(widthProgressBarMobile, [0.6, 0.61, 0.8, 0.81], [0.5, 1, 1, 0.5]);
  const opacityContactMobile = useTransform(widthProgressBarMobile, [0.8, 0.81, 1], [0.5, 1, 1]);

  const yGroupeMobile = useTransform(widthProgressBarMobile, [0, 0.01, 0.2, 0.21], [5, 0, 0, 5]);
  const yExpertiseMobile = useTransform(widthProgressBarMobile, [0.2, 0.21, 0.4, 0.41], [5, 0, 0, 5]);
  const yValeursMobile = useTransform(widthProgressBarMobile, [0.4, 0.41, 0.6, 0.61], [5, 0, 0, 5]);
  const yRealisationsMobile = useTransform(widthProgressBarMobile, [0.6, 0.61, 0.8, 0.81], [5, 0, 0, 5]);
  const yContactMobile = useTransform(widthProgressBarMobile, [0.8, 0.81, 1], [5, 0, 0]);


  // const GroupOpacity = useTransform(scrollYProgress, [0, WindowHeightValue], [0.5 , 1]);
  // const GroupY = useTransform(scrollYProgress, [0, WindowHeightValue], [0 , -10]);
  // const GroupYSpring = useSpring(GroupY, { damping: 10, stiffness: 20, mass: 1 });
  const yMenuTransition = useTransform(scrollY, [0, 0], [-100, 0]);
  const yMenuTransitionSpring = useSpring(yMenuTransition, { damping: 100, stiffness: 100, mass: 1 });

  const fillColor = useTransform(scrollYProgress, [0, 0.97, 1], ["#5b6b74", "#5b6b74", "#FFF"]);

  function gotoSection(name) {

    if (isAnimation) {
      return;
    }

    setIsAnimation(true);

    let groupeEl = document.getElementById("section-groupe");
    let expertiseEl = document.getElementById("section-anchor");
    let valeursEl = document.getElementById("section-valeurs");
    let réalisationsEl = document.getElementById("section-réalisations");
    let contactEl = document.getElementById("section-contact");

    const groupOffset = groupeEl !== null && groupeEl.offsetTop;
    const expertiseOffset = expertiseEl !== null && expertiseEl.offsetTop;
    const valeursOffset = valeursEl !== null && valeursEl.offsetTop;
    const realisationOffset = réalisationsEl !== null && réalisationsEl.offsetTop;
    const contactOffset = contactEl !== null && contactEl.offsetTop;

    name === "home" && window.scrollTo({ top: 0, behavior: 'smooth' });
    name === "le groupe" && window.scrollTo({ top: groupOffset, behavior: 'smooth' });
    name === "expertise" && window.scrollTo({ top: expertiseOffset, behavior: 'smooth' });
    name === "valeurs" && window.scrollTo({ top: valeursOffset, behavior: 'smooth' });
    name === "réalisations" && window.scrollTo({ top: realisationOffset, behavior: 'smooth' });
    name === "contact" && window.scrollTo({ top: contactOffset, behavior: 'smooth' });

    setTimeout(function () {
      setIsAnimation(false);
      setMenuIsOpen(false);
    }, 500);
  }

  const HeaderTransitionMobile = {
    initial: {
      opacity: 0,
      x: "100%"
    },
    enter: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        damping: 10,
        stiffness: 20,
        mass: 0.5,
        duration: 0.5,
        staggerChildren: 0.1,
      },
    },
    exit: {
      opacity: 0,
      x: "100%",
      transition: {
        delay: 0.2,
        duration: 0.35,
      },
    },
  };

  const childrenMenu = {
    initial: {
      x: 100
    },
    enter: {
      x: 0,
      transition: {
        type: 'tween',
        duration: 0.2,
      },
    },
    exit: {
    },
  };


  const HeaderTransition = {
    initial: {
      opacity: 1,
      y: -100
    },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        damping: 10,
        stiffness: 20,
        mass: 0.5,
        duration: 0.5,
      },
    },
    exit: {
      opacity: 1,
      y: -100,
      transition: {
        delay: 0.2,
        duration: 0.35,
      },
    },
  };


  return (
    <>
      <AnimatePresence>
        {(isIntro !== true && !isMobileOnly) &&
          <motion.header
            key="Header"
            initial="initial"
            animate="enter"
            exit="exit"
            id="Header"
            variants={HeaderTransition}
            className="z-100 flex items-center justify-center fixed backdrop-blur bg-white bg-opacity-25 top-0 left-0 w-screen h-16">
            <div className="flex w-11/12 h-full items-center justify-between">
              <div className="w-40 cursor-pointer" onClick={() => gotoSection("home")}>
                <LogoGreyEdmond />
              </div>
              <div className="relative h-full select-none">
                <motion.div style={{ scaleX: widthProgressBarSpring }} className="origin-top-left absolute h-1 w-full background-gray-edmond bottom-0 left-0"></motion.div>
                <ul className="flex h-full text-xs desktop:text-sm uppercase text-grey-edmond sofia-bold">
                  <motion.li style={{ opacity: opacityGroupe, y: yGroupe }} className="animate hover:opacity-100  transition-all  duration-300 h-full flex items-center justify-center cursor-pointer w-24 desktop:w-32" onClick={() => gotoSection("le groupe")}>Le groupe</motion.li>
                  <motion.li style={{ opacity: opacityExpertise, y: yExpertise }} className="animate hover:opacity-100 transition-all duration-300 h-full flex items-center justify-center cursor-pointer w-24 desktop:w-32" onClick={() => gotoSection("expertise")}>expertise</motion.li>
                  <motion.li style={{ opacity: opacityValeurs, y: yValeurs }} className="animate  hover:opacity-100 transition-all  duration-300 h-full flex items-center justify-center cursor-pointer w-24 desktop:w-32" onClick={() => gotoSection("valeurs")}>valeurs</motion.li>
                  <motion.li style={{ opacity: opacityRealisations, y: yRealisations }} className="animate hover:opacity-100  transition-all  duration-300 h-full flex items-center justify-center cursor-pointer w-24 desktop:w-32" onClick={() => gotoSection("réalisations")}>réalisations</motion.li>
                  <motion.li style={{ opacity: opacityContact, y: yContact }} className="animate hover:opacity-100  transition-all  duration-300 h-full flex items-center justify-center cursor-pointer w-24 desktop:w-32" onClick={() => gotoSection("contact")}>contact</motion.li>
                </ul>
              </div>
            </div>
          </motion.header>
        }
      </AnimatePresence>
      <AnimatePresence>
        {(isIntro !== true && isMobileOnly) &&
          <div
            className="z-150 flex justify-center items-center fixed top-0 left-0 w-full h-20">
            <div className="h-7 flex justify-between items-center w-10/12">
              <motion.div onClick={() => gotoSection("home")} style={{ fill: fillColor }} className="w-8 h-full"><LogoMobile className="w-full" /></motion.div>
              <motion.div style={{ stroke: fillColor }} onClick={() => toogleMenu(menuIsOpen)} className="w-auto h-full p-2">
                {menuIsOpen !== true && <SvgBurger />}
                {menuIsOpen && <SvgCroix className="w-auto h-5" />}
              </motion.div>
            </div>
          </div>
        }
      </AnimatePresence>
      <AnimatePresence>
        {menuIsOpen &&
          <motion.div
            initial="initial"
            animate="enter"
            exit="exit"
            key="headermobile"
            variants={HeaderTransitionMobile}
            className="fixed p-10 pt-20 w-3/4 z-140 flex flex-col justify-center items-start top-0 right-0 h-screen bg-white bg-opacity-75 backdrop-blur-strong">
            <div className="flex flex-col mt-5 xs:mt-10 items-end justify-start h-full w-full pt-0">
              <div onClick={() => gotoSection("home")} className="w-11/12">
                <LogoGreyEdmond />
              </div>
              <ul className="flex items-end justify-start mt-10 flex-col h-full text-base uppercase w-full text-grey-edmond sofia-bold">
                <motion.li variants={childrenMenu}  style={{ opacity: opacityGroupeMobile, y: yGroupeMobile }} className="py-5 animate hover:opacity-100  transition-all  duration-300 flex items-center justify-end cursor-pointer w-32" onClick={() => gotoSection("le groupe")}>Le groupe</motion.li>
                <motion.li variants={childrenMenu} style={{ opacity: opacityExpertiseMobile, y: yExpertiseMobile }} className="py-5 animate hover:opacity-100 transition-all duration-300 flex items-center justify-end cursor-pointer w-32" onClick={() => gotoSection("expertise")}>expertise</motion.li>
                <motion.li variants={childrenMenu} style={{ opacity: opacityValeursMobile, y: yValeursMobile }} className="py-5 animate  hover:opacity-100 transition-all  duration-300  flex items-center justify-end cursor-pointer w-32" onClick={() => gotoSection("valeurs")}>valeurs</motion.li>
                <motion.li variants={childrenMenu} style={{ opacity: opacityRealisationsMobile, y: yRealisationsMobile }} className="py-5 animate hover:opacity-100  transition-all  duration-300 flex items-center justify-end cursor-pointer w-32" onClick={() => gotoSection("réalisations")}>réalisations</motion.li>
                <motion.li variants={childrenMenu} style={{ opacity: opacityContactMobile, y: yContactMobile }} className="py-5 animate hover:opacity-100  transition-all  duration-300 flex items-center justify-end cursor-pointer w-32" onClick={() => gotoSection("contact")}>contact</motion.li>
              </ul>
            </div>
          </motion.div>
        }
      </AnimatePresence>
    </>
  )
}
export default connect(state => ({
  isIntro: state.IsIntro.isIntro,
  scrollProgressValue : state.ScrollProgressValue.scrollProgressvalue,
}), null)(Header);