import * as React from "react"

function LogoOO(props) {
  return (
    <svg
      width={743.003}
      height={609.888}
      viewBox="0 0 743.003 609.888"
      {...props}
    >
      <g data-name="Groupe 3886" fill="none">
        <path
          data-name="Trac\xE9 9472"
          d="M604.949 280.731a192.969 192.969 0 01-.005 47.6c.6-7.875 1-15.8 1-23.825 0-8.008-.398-15.916-.995-23.775z"
          strokeMiterlimit={10}
        />
        <path
          data-name="Trac\xE9 9473"
          d="M273.128 304.506c0-74.157 38.157-136.233 98.912-162.747 60.629 26.514 97.82 88.59 97.82 162.747 0 74.177-37.191 136.729-97.82 163.486-60.755-26.756-98.912-89.309-98.912-163.486z"
        />
        <path
          data-name="Trac\xE9 9474"
          d="M138.054 328.144a188.777 188.777 0 010-47.233c-.592 7.8-.991 15.645-.991 23.6s.394 15.828.991 23.633z"
          strokeMiterlimit={10}
        />
        <path
          data-name="Trac\xE9 9475"
          d="M302.795.499a308.053 308.053 0 0168.7 7.78C244.549 37.533 148.273 147.033 138.05 280.913a188.781 188.781 0 000 47.232c9.933 87.567 74.6 153.777 165.595 153.777 25.093 0 48.056-4.956 68.392-13.929 60.629-26.757 97.819-89.309 97.819-163.486 0-74.158-37.191-136.233-97.819-162.747a169.515 169.515 0 0168.168-13.792c91.732 0 154.978 65.409 164.74 152.763.6 7.858.995 15.766.995 23.776 0 8.026-.4 15.95-1 23.825-10.185 133.843-105.364 243.819-233.452 273.232a306.548 306.548 0 01-68.7 7.822C135.703 609.386.493 472.45.493 304.507S135.71.499 302.795.499z"
        />
        <path
          data-name="Trac\xE9 9476"
          d="M138.05 280.913c10.224-133.88 106.5-243.376 233.442-272.634a301.579 301.579 0 0167.862-7.78c169.648 0 303.149 136.072 303.149 304.009s-133.5 304.879-303.149 304.879a300.107 300.107 0 01-67.862-7.822c128.088-29.413 223.267-139.389 233.452-273.232a192.953 192.953 0 00.005-47.6c-9.763-87.354-73.008-152.763-164.74-152.763a169.515 169.515 0 00-68.168 13.792c-60.755 26.514-98.912 88.589-98.912 162.747 0 74.177 38.157 136.729 98.912 163.486-20.336 8.973-43.3 13.929-68.392 13.929-91 0-155.662-66.21-165.595-153.777-.6-7.806-1-15.674-1-23.637s.403-15.801.996-23.597z"
        />
      </g>
    </svg>
  )
}

export default LogoOO